import React, { useEffect } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";

const Maintenance = () => {
  const [isMobileTablet] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (window.innerWidth > 768) {
      window.location.replace("https://supplier.kkonnect.io/login");
    }
  }, [isMobileTablet]);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        bg="#E2F1EE"
        height="100vh"
        flexDirection="column"
      >
        <Text
          color="#4BA18A"
          fontSize={{ xs: "1.125rem", lg: "1.5rem" }}
          fontWeight="500"
        >
          {isMobileTablet
            ? "Our mobile app is underway!"
            : "We have permanently moved to a new portal!"}
        </Text>
        <Image
          mt="2rem"
          src="/assets/images/maintenance.png"
          width="21.74rem"
          alt="maintenance"
          height="auto"
        />
        <Box
          textAlign="center"
          fontSize={{ xs: "1rem", lg: "1.4rem" }}
          fontWeight="500"
          mt="2rem"
        >
          <Text>Please sign in on</Text>
          <Text
            color="#4BA18A"
            cursor="pointer"
            onClick={() =>
              window.location.replace("https://supplier.kkonnect.io/login")
            }
          >
            supplier.kkonnect.io
          </Text>
          <Text>using computer or laptop for now.</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Maintenance;
