import { Box, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { register } from "./serviceWorkerRegistration";

export function ServiceWorkerPrompt() {
  const [showSWPrompt, setSWPrompt] = useState(false);

  function handleUpdate() {
    setSWPrompt(true);
  }

  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      return;
    }

    register({
      onUpdate: handleUpdate,
    });

    let refreshing = false;
    // When the service worker is updated, set state to false. and reload the page
    navigator.serviceWorker.addEventListener("controllerchange", (e) => {
      setSWPrompt(false);
      // Flag is required, otehrwise chrome will get stuck in a loop
      if (refreshing) return;

      refreshing = true;
      window.location.reload();
    });

    async function checkUpdates() {
      try {
        const registration = await navigator.serviceWorker.getRegistration("/");

        // no service worker installed
        if (!registration) {
          return;
        }
        const updatedRegistration =
          (await registration.update()) as unknown as ServiceWorkerRegistration | void;

        // The waiting service worker might be present in the newly fetched serviceWorkerRegisration or the old one
        if (updatedRegistration?.waiting || registration.waiting) {
          setSWPrompt(true);
        }
      } catch (e) {
        console.error(e);
      }
    }

    checkUpdates();

    const intervalID = setInterval(checkUpdates, 1000 * 60 * 30); // 30 MIN

    return () => clearInterval(intervalID);
  }, []);

  return showSWPrompt ? (
    <Box
      className="sw-prompt"
      display={"flex"}
      gap={4}
      p={2}
      zIndex={10}
      backgroundColor={"rgba(10, 10, 10, 0.6)"}
      color={"white"}
      borderRadius={"8px"}
      alignItems={"center"}
    >
      <Button
        size={"sm"}
        fontSize={{
          xs: "sm",
          sm: "md",
          md: "lg",
        }}
        variant={"outline"}
        color={"white"}
        onClick={() => {
          "serviceWorker" in navigator &&
            navigator.serviceWorker
              .getRegistration("/")
              .then((registration) => {
                if (registration && registration.waiting) {
                  registration.waiting.postMessage({ type: "SKIP_WAITING" });
                  setSWPrompt(false);
                } else throw new Error("Activation: Service worker not found");
              })
              .catch((e) => {
                console.error(e);
              });
        }}
        _hover={{
          backgroundColor: "rgba(200, 200, 200, 0.5)",
        }}
        _active={{
          backgroundColor: "rgba(200, 200, 200, 0.4)",
        }}
      >
        Update Portal
      </Button>
    </Box>
  ) : (
    <></>
  );
}
