import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/store/store";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

const breakpoints = {
  xs: "0px",
  sm: "450px",
  md: "768px",
  lg: "950px",
  xl: "1200px",
  "2xl": "1536px",
};
const components = {
  Steps,
};

const colors = {
  background: "#FBFBFB",
  white: "#FFFFFF",
  linkBlue: "#0087ee",
  errorRed: "rgb(253, 237, 237)",
  warningOrange: "rgb(255, 244, 229)",
  infoBlue: "rgb(229, 246, 253)",
  successGreen: "rgb(237, 247, 237)",
  brand: {
    "900": "rgba(0, 22, 98, 1)",
    "500": "rgba(0, 22, 98, 1)",
    "100": "rgba(0, 135, 238, 0.04)",
  },
  kio: {
    "900": "#E26C3A ", //primary dark -- #BC661F
    "800": "#F8874F", // primary default -- #E77E26
    "700": "#F8874F",
    "600": "#F8874F",
    "500": "#F8874F",
    "400": "#F8874F",
    "300": "rgba(248, 135, 79, 0.3)", // 30
    "200": "rgba(248, 135, 79, 0.2)", //20
    "100": "rgba(248, 135, 79, 0.1)", //10
    "50": "rgba(248, 135, 79, 0.05)", //5
  },
  secondary: {
    // "900": "#268AE7",
    // "500": "#268AE7",
    // "200": "rgba(38, 138, 231, 0.2)",
    // "100": "rgba(38, 138, 231, 0.1)",
    // "50": "rgba(38, 138, 231, 0.05)",

    "900": "#000000",
    "300": "rgba(0, 0, 0, 0.3)",
    "200": "rgba(0, 0, 0, 0.2)",
    "100": "rgba(0, 0, 0, 0.1)",
    "50": "rgba(0, 0, 0, 0.05)",
  },
  tertiary: {
    "900": "rgba(239, 111, 108, 1)",
  },
  dark: {
    "60": "rgba(19, 27, 24, 0.6)",
    "40": "rgba(19, 27, 24, 0.4)",
    "20": "rgba(19, 27, 24, 0.2)",
    "10": "rgba(19, 27, 24, 0.1)",
    "5": "rgba(19, 27, 24, 0.05)",
  },
};
const fontSizes = {
  "8px": "0.5rem", // 8px
  "10px": "0.625rem", // 10px
  xs: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  md: "1rem", // 16px
  lg: "1.125rem", // 18px
  xl: "1.25rem", // 20px
  "2xl": "1.5rem", // 24px
  "3xl": "1.875rem", // 30px
  "4xl": "2.25rem", // 36px
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",

  "15px": "0.93rem",
  "22px": "1.375rem",
  "32px": "2rem", // 32px
};

const fonts = {
  body: "Rubik, Roboto, Ubuntu, system-ui, sans-serif",
  heading: "Georgia, serif",
  mono: "Menlo, monospace",
};

const theme = extendTheme({
  breakpoints,
  components,
  colors,
  fontSizes,
  fonts,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
