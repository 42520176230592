const file: any = [
  {
    id: null,
    name: null,
    size: null,
    loading: true,
    success: false,
    error: false,
  },
];

export const quoteFileState = function (state = file, action: any) {
  const { type, payload } = action;

  switch (type) {
    case "Add_In_Progress":
      return [
        ...state,
        {
          id: payload.id,
          name: payload.name,
          size: payload.size,
          loading: true,
          success: false,
          error: false,
        },
      ];
    case "Add_File":
      return [
        ...state,
        {
          id: payload.id,
          name: payload.name,
          size: payload.size,
          loading: false,
          success: true,
          error: false,
        },
      ];
    case "Delete_Flle":
      return state.filter((item) => item.id !== payload.id);
    case "Upload_Error":
      let itemIndex = state.findIndex((item) => item.id === payload.id);
      let newList = [...state];
      newList[itemIndex].error = true;
      newList[itemIndex].success = true;
      newList[itemIndex].loading = false;
      newList[itemIndex].size = null;
      return (state = newList);
    case "Delete_All_File":
      return [];
    default:
      return state;
  }
};
