export const INSTANCE_URL_SUCCESS = "INSTANCE_URL_SUCCESS";
export const INSTANCE_URL_FAILURE = "INSTANCE_URL_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_LOGIN_DETAILS = "UPDATE_LOGIN_DETAILS";
export const LOGOUT = "LOGOUT";
export const API_LOADING = "API_LOADING";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SUCCESS_MSG = "SUCCESS_MSG";
export const ERROR_MSG = "ERROR_MSG";
export const WARNING_MSG = "WARNING_MSG";
export const INFO_MSG = "INFO_MSG";
export const UPDATE_HEADER_TITLE = "UPDATE_HEADER_TITLE";
export const UPDATE_CONFIGURE_NOW_DIALOG = "UPDATE_CONFIGURE_NOW_DIALOG";
export const UPDATE_SIGNUP_STATE = "UPDATE_SIGNUP_STATE";
export const PAGE_LOADING = "PAGE_LOADING";
export const UPDATE_NEXT_STEP_STATE = "UPDATE_NEXT_STEP_STATE";
export const UPDATE_ONBOARD_STATUS = "UPDATE_ONBOARD_STATUS";
