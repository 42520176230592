import { combineReducers } from "redux";
import { auth, signUpState } from "./auth";
import { quoteFileState } from "./quote";
import {
  message,
  apiLoading,
  pageLoading,
  headerTitle,
  showConfigureNowDialog,
  onBoardStatus,
} from "./messages";
import { nextStep } from "./rfq";

export default combineReducers({
  auth,
  signUpState,
  message,
  apiLoading,
  pageLoading,
  headerTitle,
  showConfigureNowDialog,
  quoteFileState,
  nextStep,
  onBoardStatus,
});
