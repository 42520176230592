import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  UPDATE_LOGIN_DETAILS,
  UPDATE_SIGNUP_STATE,
  LOGOUT,
} from "../actions/types";
import Cookies from "js-cookie";

const localuser = Cookies.get();
const user = localuser.user_id ? localuser.user_id : null;

const email = localuser.user_id ? localuser.user_id : null;
const full_name = localStorage.getItem("supplierFullName");

const initialState =
  user && user !== "Guest"
    ? {
        isLoggedIn: true,
        user: { email, full_name },
      }
    : {
        isLoggedIn: false,
        user: null,
      };

export const auth = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case UPDATE_LOGIN_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case LOGOUT:
      return {
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

const signUp = {
  signUpLoading: false,
  signUpEmail: "",
  signUpPwd: "",
};

export const signUpState = function (state = signUp, action: any) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SIGNUP_STATE:
      return payload;
    default:
      return state;
  }
};
