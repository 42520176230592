import { UPDATE_NEXT_STEP_STATE } from "../actions/types";

const nextStepState = {};

export const nextStep = function (state = nextStepState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_NEXT_STEP_STATE:
      return payload;
    default:
      return state;
  }
};
