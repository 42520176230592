// import { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import RoutesList from "./routes/routes";
// import PrivateLayout from "./layouts/privatelayout";
// import Login from "./pages/access/login";
// import PublicLayout from "./layouts/publiclayout";
// import Dashboard from "./pages/dashboard";
// import { useDispatch, useSelector } from "react-redux";
// import axios, { AxiosError, AxiosResponse } from "axios";
// import { setLoading } from "./store/actions/messages";
// import Toastr from "./components/basicComponents/toastr";
// import "react-datepicker/dist/react-datepicker.css";
// import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { ServiceWorkerPrompt } from "./serviceWorkerPrompt";
// import { ErrorPage } from "./pages/error/error";
// import { datadogRum } from "@datadog/browser-rum";
// import Cookies from "js-cookie";
import Maintenance from "./layouts/Maintenance";

const isProduction = process.env.NODE_ENV === "production";

// if (isProduction) {
//   const tagManagerArgs = {
//     gtmId: "GTM-WFDDSBB",
//   };

//   TagManager.initialize(tagManagerArgs);
//   Sentry.init({
//     dsn: "https://838a2956c217409a84d68b27b6ec94c4@o1134087.ingest.sentry.io/6659251",
//     integrations: [new BrowserTracing()],
//     environment: process.env.NODE_ENV,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.5,
//     initialScope: {
//       user: {
//         email: String(localStorage.getItem("supplierEmail")),
//         username: String(localStorage.getItem("supplierFullName")),
//       },
//     },
//   });

//   // --------------------DataDog implementation--------------------
//   datadogRum.setUser({
//     name: Cookies.get("full_name") || "Guest User",
//     email: Cookies.get("user_id") || "Guest Email",
//   });
//   datadogRum.init({
//     applicationId: "765f8f95-4c02-4315-81c5-7103def7f9c0",
//     clientToken: "pub93fb50bf9034fbc4b4a6bf7dc21499c3",
//     site: "datadoghq.com",
//     service: "supplier-portal",
//     env: window.location.hostname.split(".")[0],
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: "mask-user-input",
//   });

//   datadogRum.startSessionReplayRecording();
//   // --------------------DataDog implementation--------------------
// }

const _App = isProduction ? Sentry.withProfiler(App) : App;

function App(props) {
  // const [authState, showConfigureNowDialog, onboarded] = useSelector(
  //   (state: any) => [
  //     state.auth,
  //     state.showConfigureNowDialog,
  //     state.onBoardStatus,
  //   ]
  // );
  // const dispatch = useDispatch();
  // const isLoggedIn = authState.isLoggedIn;

  // Add a response interceptor
  // axios.interceptors.response.use(
  //   function (response: AxiosResponse) {
  //     dispatch(setLoading(false));
  //     return response;
  //   },
  //   function (error: AxiosError) {
  //     dispatch(setLoading(false));
  //     return Promise.reject(error);
  //   }
  // );

  // useEffect(() => {
  //   if (isLoggedIn && isProduction) {
  //     // clearing the guest user session
  //     datadogRum.clearUser();
  //     datadogRum.stopSession();
  //     datadogRum.stopSessionReplayRecording();

  //     //setting  the logged in user session
  //     datadogRum.setUser({
  //       name: Cookies.get("full_name"),
  //       email: Cookies.get("user_id"),
  //     });
  //     datadogRum.init({
  //       applicationId: "765f8f95-4c02-4315-81c5-7103def7f9c0",
  //       clientToken: "pub93fb50bf9034fbc4b4a6bf7dc21499c3",
  //       site: "datadoghq.com",
  //       service: "supplier-portal",
  //       env: window.location.hostname.split(".")[0],
  //       sessionSampleRate: 100,
  //       sessionReplaySampleRate: 20,
  //       trackUserInteractions: true,
  //       trackResources: true,
  //       trackLongTasks: true,
  //       defaultPrivacyLevel: "mask-user-input",
  //     });

  //     datadogRum.startSessionReplayRecording();
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      <ServiceWorkerPrompt />
      <Routes>
        <Route path={"*"} element={<Maintenance />} />
        {/* {RoutesList.filter((route) => route.type === "public").map(
          (route, index) =>
            isLoggedIn ? (
              <Route
                key={index}
                path="/dashboard"
                element={
                  <Suspense>
                    <PrivateLayout component={Dashboard} />
                  </Suspense>
                }
              />
            ) : (
              <Route
                key={index}
                path={route.link}
                element={
                  <Suspense>
                    <PublicLayout component={route.component} />
                  </Suspense>
                }
              />
            )
        )}

        {RoutesList.filter((route) => route.type === "internal").map(
          (route, index) =>
            isLoggedIn ? (
              <Route
                key={index}
                path={route.link}
                element={
                  <Suspense>
                    <PrivateLayout component={route.component} />
                  </Suspense>
                }
              />
            ) : (
              <Route
                key={index}
                path="/login"
                element={
                  <Suspense>
                    <PublicLayout component={Login} />
                  </Suspense>
                }
              />
            )
        )}

        <Route
          path={"/"}
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" replace={false} />
            ) : (
              <Navigate to="/login" replace={false} />
            )
          }
        />

        <Route
          path={"/login"}
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" replace={false} />
            ) : (
              <Navigate to="/login" replace={false} />
            )
          }
        />

        <Route
          path={"/register"}
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" replace={false} />
            ) : (
              <Navigate to="/login" replace={false} />
            )
          }
        />

        {isLoggedIn ? (
          <Route
            path="*"
            element={
              <Suspense>
                {(function () {
                  if (showConfigureNowDialog) {
                    return (
                      <Navigate to={"/settings/capability"} replace={false} />
                    );
                  } else if (!onboarded) {
                    return (
                      <Navigate
                        to={"/settings/BusinessInformation"}
                        replace={false}
                      />
                    );
                  } else {
                    return <PrivateLayout component={ErrorPage} />;
                  }
                })()}
              </Suspense>
            }
          />
        ) : (
          <Route
            path="*"
            element={
              <Suspense>
                <Navigate to="/login" replace={false} />
              </Suspense>
            }
          />
        )} */}
      </Routes>
      {/* <Toastr /> */}
    </>
  );
}

export default _App;
